import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import { withTranslation } from 'react-i18next';
import { timeView } from 'utils/utils';
import NumberFormat from 'react-number-format';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class MyBudget extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
        }
    }

    componentDidMount = async () => {
        const { userBudget, getUserBudgetResp } = this.props.myShopApiStore;

        if(userBudget === null || this.state.loading){
            await getUserBudgetResp();
            this.setState({loading: false});
        }   
    }

    componentWillUnmount = () => {
        const { setUserBudget } = this.props.myShopApiStore;

        this.setState({loading: true});
        setUserBudget(null);
    }

    render() {
        const { t } = this.props;
        const { userBudget } = this.props.myShopApiStore;
        const { timeFormat, currencyName } = this.props.myShopApiStore.shopData.country ?? {};

        return (
        <div className={style.container}>
            <div className={style.Header}>
                { t('MyBudget')}
            </div>
            { 
                this.state.loading ? 
                    <div id="about" className={style.About}>
                        ...
                    </div>
                :
                    <div id="about" className={style.About}>
                        <span className={style.Bigger}>{ t('YourDailyBudgetIs') }: {isNaN(userBudget.day) ? userBudget.day : <NumberFormat value={userBudget.day} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />}</span>
                        <br/>
                        <span className={style.Bigger}>{ t('BudgetLeft') }: {isNaN(userBudget.now) ? userBudget.now : <NumberFormat value={userBudget.now} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />}</span>
                        <br/>
                        <br/>
                        <br/>
                        { t('PayAttentionBudget') } {timeView('00:00', timeFormat)}-{timeView('23:59', timeFormat)}
                        <br/>
                        { t('BudgetNotMovedToNextDay') }
                        <br/>
                        { t('BudgetRenew') } {timeView('00:00', timeFormat)}
                    </div>
            }
        </div>
        )
    }
}
export default withTranslation()(MyBudget);
